.boards {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background: var(--light-color);
}

.boards__header {
  display: flex;
  flex-direction: column;
}

.boards__header-title {
  align-self: center;
}
