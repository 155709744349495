.boards-list {
  align-self: flex-start;
  width: 100%;
}

.boards-list__list {
  list-style: none;
  padding-left: 0;
  width: 100%;
}

.boards-list__list-item {
  border-bottom: 1px solid var(--primary-color);
}

.boards-list__list-item-link {
  padding: 1rem;
  text-decoration: none;
  display: block;
}
.boards-list__list-item-link:hover {
  background: white;
}
