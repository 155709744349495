.form {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 80px 200px;
}

.form__label {
  align-self: center;
  padding: 7px;
}
.form__input {
  padding: 7px;
  background: white;
  border: 1px inset white;
}

.form__button:hover,
.form__button:focus {
  background: var(--light-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
