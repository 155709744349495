.header__nav {
  display: flex;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid var(--primary-color);
  padding: 10px;
}

.header__nav-group {
  display: flex;
  background: white;
  padding-bottom: 1px;
}

.header__nav-item {
  padding: 5px;
  text-decoration: none;
  color: var(--dark-color);
  font-size: 1.5rem;
}
