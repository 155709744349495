.threads {
  padding: 0 2rem;
}

.threads__list {
  list-style: none;
  padding: 0;
}

.threads__list-item {
  border-bottom: 1px solid var(--primary-color);
}

.threads__list-item-link {
  display: block;
  text-decoration: none;
}
.threads__list-item-link:hover {
  background: white;
}

.threads__list-item-link h2,
p {
  background: inherit;
  margin: 0;
  padding: 0.5rem;
}
